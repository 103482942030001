// Gatsby supports TypeScript natively!
import React, { useRef } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Button from '@material-ui/core/Button'

/*
#51255e = purple
#c8514b = red/orange
#f4cd62 = yellow

dark blue = #1c2b3d
real dark blue = #0f2034
light blue = #3c4b62
*/


type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const Landing = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  return (
        <Layout location={location} title={siteTitle}>
          {/* <div style={{width: 400, height: 400, background: `url(${data.hero_image.childImageSharp.fixed})`}}></div> */}
          <SEO title="Dev Internship Dotcom" />
          <div>
            <div style={{paddingBottom: 16}}>
              <p>To get started with either the premium internship experience, or to just watch the videos for free, sign up below. After signing up you'll receive information on how to access the Dev Internship Dotcom application. From there you can get started with the internship or simply watch some of the content.</p>
              <p>By signing up below you are creating an account and subscribing to any relevant information that we feel like we need to share with our users (no spam, or selling your information, we're engineers not souless suits), from which you can unsubcribe at anytime. Please note that if you sign up for the full internship experience you will be provisioned a work email that will be come your primary contact information for the internship experience.</p>
            </div>
<div id="mc_embed_signup">
<form action="https://devinternship.us10.list-manage.com/subscribe/post?u=f01b20ca328f2580a4a67ef2e&amp;id=2ae2fb69fe" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll" style={{textAlign: 'center'}}>
	
	<input type="email" name="EMAIL" className="MuiInput-root email" id="mce-EMAIL" placeholder="your@emailaddress.com" required />
    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
      <input type="text" name="b_f01b20ca328f2580a4a67ef2e_2ae2fb69fe" tabindex="-1"/>
    </div>
    <div className="clear" style={{textAlign: 'center', marginTop: 16}}>
      <Button variant="contained" color="primary" type="submit" value="Signup" name="signup" id="mc-embedded-subscribe" className="button">Signup</Button></div>
    </div>
</form>
</div>
          </div>
        </Layout>

  )
}

export default Landing

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
